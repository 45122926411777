<template>
  <b-nav-item
    :to="item.route"
    active-class="active"
  >
    <i :class="item.icon" v-if="item.icon" />
    <span>{{ item.title }}</span> 
  </b-nav-item>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    active() {
      return this.$route.name == this.item.route.name
    }
  }
}
</script>
