import _ from 'lodash'

const menu = [
  {
    cap: 'Main',
    relate: [
      'BoDashboard',
      'BoProspect',
      'BoLaporanKunjungan'
    ]
  },
  {
    title: 'Dashboard',
    route: {name: 'BoDashboard'},
    menu: 'BoDashboard',
    icon: 'icon-home4',
  },
  {
    title: 'Prospect From Portal',
    route: {
      name: 'BoProspect'
    },
    menu: 'BoProspect',
    icon: 'icon-profile',
  },
  {
    title: 'Survey / Visit',
    route: {
      name: 'BoLaporanKunjungan'
    },
    menu: 'BoLaporanKunjungan',
    icon: 'icon-calendar52',
  },
  {
    cap: 'Fasilitas',
    relate: [
      'BoDebiturPerusahaan',
      'BoDebiturIndividu',
      'Report',
      'BoReport'
    ]
  },
  {
    title: 'Debitur',
    icon: 'icon-users2',
    children: [
    {
      title: 'Perusahaan',
      route: {
        name: 'BoDebiturPerusahaan'
      },
      menu: 'BoDebiturPerusahaan'
    },
    {
      title: 'Individual',
      route: {
        name: 'BoDebiturIndividual'
      },
      menu: 'BoDebiturIndividual'
    }
    ]
  },
  {
    title: 'Fasilitas Inquiry',
    icon: 'icon-cog',
    menu: 'BoLoan',
    route: {
      name: 'BoLoan'
    },
  },
  {
    title: 'Report List',
    icon: 'icon-cog',
    menu: 'BoReport',
    route: {
      name: 'BoReport'
    },
  },
  {
    cap: 'Other',
    relate: [
      'Users',
      'BoUserLevel',
      'BoFasilitas',
      'BoMGolDebitur',
      'BoMFasilitasDrop',
      'BoMDocType',
      'BoMDepartment',
      'BoMColateral',
      'BoMBidangUsaha',
      'BoMKodeUsaha',
      'BoMTargetPencairan',
      'BoMPendidikan',
      'BoMEmailTemplate'
      // 'BoMJenisUsaha'
    ]
  },
  {
    title: 'Master Settings',
    icon: 'icon-wrench2',
    children: [
      {
        title: 'Tipe Dokumen',
        route: {
          name: 'BoMDocType'
        },
        menu: 'BoMDocType'
      },
      {
        title: 'Bucket Flow',
        route: {
          name: 'BoMBucketFlow'
        },
        menu: 'BoMBucketFlow'
      },
      {
        title: 'Collateral',
        route: {
          name: 'BoMColateral'
        },
        menu: 'BoMColateral'
      },
      {
        title: 'Bidang Usaha',
        route: {
          name: 'BoMBidangUsaha'
        },
        menu: 'BoMBidangUsaha'
      },
      {
        title: 'Bentuk Bidang Usaha',
        route: {
          name: 'BoMKodeUsaha'
        },
        menu: 'BoMKodeUsaha'
      },
      /*
      {
        title: 'Jenis Usaha',
        route: {
          name: 'BoMJenisUsaha'
        },
        menu: 'BoMJenisUsaha'
      },
      */
      {
        title: 'Pembiayaan',
        route: {
          name: 'BoFasilitas'
        },
        menu: 'BoFasilitas'
      },
      {
        title: 'Pembiayaan Drop',
        route: {
          name: 'BoMFasilitasDrop'
        },
        menu: 'BoMFasilitasDrop'
      },
      {
        title: 'Golongan Debitur',
        route: {
          name: 'BoMGolDebitur'
        },
        menu: 'BoMGolDebitur'
      },
      {
        title: 'Departemen',
        route: {
          name: 'BoMDepartment'
        },
        menu: 'BoMDepartment'
      },
      {
        title: 'Target Pencairan Bulanan',
        route: {
          name: 'BoMTargetPencairan'
        },
        menu: 'BoMTargetPencairan'
      },
      {
        title: 'Pendidikan',
        route: {
          name: 'BoMPendidikan'
        },
        menu: 'BoMPendidikan'
      },
      {
        title: 'Notifikasi',
        route: {
          name: 'BoMNotification'
        },
        menu: 'BoMNotification'
      },
      {
        title: 'Email Template',
        route: {
          name: 'BoMEmailTemplate'
        },
        menu: 'BoMEmailTemplate'
      }
    ],
  },
  {
    title: 'User Management',
    icon: 'icon-users',
    children: [
      {
        title: 'User List',
        route: {name: 'Users'},
        menu: 'Users'
      },
      {
        title: 'User Level',
        route: {name: 'BoUserLevel'},
        menu: 'BoUserLevel'
      },
    ],
  },
  {
    title: 'AuditTrail',
    route: {
      name: 'BoAuditTrail'
    },
    icon: 'icon-check',
    menu: 'BoAuditTrail'
  },
  {
    cap: ' '
  },
  {
    cap: ' '
  },
]

let nav = menu
const accessAll = localStorage.getItem('u_menu_all')
if (accessAll == 'N') {
  let menuRole = JSON.parse(localStorage.getItem('u_menu') || '[]')
  menuRole = _.map(menuRole, v => {
    return v.menu
  })
  
  nav = _.filter(nav, v => {
    if (v.cap) {
      return _.filter(v.relate, v2 => {
        return menuRole.indexOf(v2) > -1
      }).length >= 1
    } 
    else if (v.children) {
      return _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      }).length >= 1
    } 
    else {
      return menuRole.indexOf(v.menu) > -1
    }
  })

  nav = _.map(nav, v => {
    if (v.children) {
      v.children = _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      })
    }

    return v
  })
}

export default nav