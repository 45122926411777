<template>
  <div class="sidebar sidebar-dark sidebar-main sidebar-expand-md">
    <!-- Sidebar mobile toggler -->
    <div class="sidebar-mobile-toggler text-center">
      <a href="#" class="sidebar-mobile-main-toggle">
        <i class="icon-arrow-left8"></i>
      </a>
      Navigation
      <a href="#" class="sidebar-mobile-expand">
        <i class="icon-screen-full"></i>
        <i class="icon-screen-normal"></i>
      </a>
    </div>
    <!-- /sidebar mobile toggler -->
    
    <!-- User menu -->
    <!--
		<div class="sidebar-user">
			<div class="card-body">
				<div class="media">
					<div class="mr-3">
						<a href="#"><img :src="uploader(user.avatar)" width="38" height="38" class="rounded-circle" alt=""></a>
					</div>
					<div class="media-body">
						<div class="media-title font-weight-semibold">{{user.fullName}}</div>
						<div class="font-size-xs opacity-50">
							<i class="icon-pin font-size-sm"></i> &nbsp;{{user.level}}
						</div>
					</div>
				</div>
			</div>
		</div>
    -->
		<!-- /user menu -->

    <bo-sidebar-menu
      :items="navigation"
    />
    <!-- End Sidebar navigation -->
    <!-- End Sidebar scroll-->
  </div>
</template>
<script>
import navigation from '@/navigation'
import BoSidebarMenu from '@/components/backend/BoSidebarMenu.vue'
import GlobalVue from '../../libs/Global.vue'

export default {
extends: GlobalVue,
  components: {
    BoSidebarMenu, 
  },
  mounted() {
    let body = document.querySelector('body')
    if(this.isDesktop){
      body.classList.remove('mini-sidebar')
    } else{
      body.classList.add('mini-sidebar')
    }
  },
  data() {
    return {
      linkWave: {
        color: "#777",
      },
      navigation,
    }
  },
}
</script>