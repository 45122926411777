<template>
  <div class="content-wrapper">
    <router-view />
  </div>
</template>
<script>
export default {
  
}
</script>
